import React from 'react';

const AwardIcon = ({ color = "#46959F" }) => (
  <svg width="81" height="83" viewBox="0 0 81 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2248 32.022C24.3629 31.7828 24.6687 31.7009 24.9078 31.839L40.6425 40.9234C40.8817 41.0615 40.9636 41.3672 40.8255 41.6064L19.8509 77.9356C19.6471 78.2885 19.1295 78.2625 18.962 77.891L15.5456 70.312C14.9274 68.9405 13.5019 68.1175 12.005 68.2678L3.73314 69.0987C3.3277 69.1394 3.04642 68.704 3.25016 68.3512L24.2248 32.022Z" stroke={color} stroke-width="3" />
    <path d="M39.3205 41.6064C39.1825 41.3672 39.2644 41.0614 39.5035 40.9234L55.2382 31.8389C55.4774 31.7009 55.7831 31.7828 55.9212 32.0219L76.8959 68.3511C77.0996 68.704 76.8183 69.1393 76.4129 69.0986L68.141 68.2678C66.6442 68.1175 65.2187 68.9405 64.6005 70.312L61.184 77.891C61.0166 78.2625 60.4989 78.2884 60.2952 77.9355L39.3205 41.6064Z" stroke={color} stroke-width="3" />
    <circle cx="40.1813" cy="31.5094" r="30.0094" fill="white" stroke={color} stroke-width="3" />
    <circle cx="40.0714" cy="31.2573" r="22.5441" stroke={color} stroke-width="3" />
    <path d="M39.6296 17.6776C39.8172 17.3221 40.3264 17.3221 40.514 17.6776L43.6755 23.6698C44.1819 24.6296 45.105 25.3003 46.1744 25.4853L52.8502 26.6404C53.2464 26.7089 53.4037 27.1933 53.1235 27.4816L48.4016 32.34C47.6452 33.1183 47.2926 34.2034 47.4471 35.2776L48.4115 41.9836C48.4687 42.3815 48.0567 42.6809 47.696 42.5035L41.6162 39.514C40.6423 39.0351 39.5013 39.0351 38.5274 39.514L32.4476 42.5035C32.0868 42.6809 31.6749 42.3815 31.7321 41.9836L32.6965 35.2776C32.851 34.2034 32.4984 33.1183 31.742 32.34L27.0201 27.4816C26.7399 27.1933 26.8972 26.7089 27.2934 26.6404L33.9692 25.4853C35.0386 25.3003 35.9616 24.6296 36.4681 23.6698L39.6296 17.6776Z" stroke={color} stroke-width="3" />
  </svg>
);

export default AwardIcon;